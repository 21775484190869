import { cn } from '~/utils/misc.ts';
import { Icon } from '~/components/ui/icon.tsx';

export function StudentStoriesSection() {
	return (
		<div className="flex w-full items-start justify-center overflow-hidden bg-primary/10 min-[900px]:items-center">
			<section className="w-full max-w-[1440px]">
				<div className="min-[900px]gap-8 flex w-full max-w-[1440px] flex-col items-center justify-center min-[900px]:flex-row min-[900px]:justify-between">
					<p className="px-4 py-4 text-center text-2xl xs:text-3xl sm:text-4xl md:px-8 md:text-5xl min-[900px]:py-0 min-[900px]:text-start">
						Join our <br className="hidden min-[900px]:inline" /> exclusive and
						verified <br className="hidden min-[900px]:inline" /> university
						network
					</p>
					<div className="flex h-[500px] items-center overflow-hidden">
						<div className="flex animate-infinite-scroll flex-col gap-6 px-4 [animation-duration:15000ms] md:px-8 md:hover:paused">
							<StudentStoriesColumn />
							<StudentStoriesColumn />
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

function StudentStoriesColumn() {
	return (
		<>
			<StudentStory
				name="Sonny"
				subtitle="Sophomore at University of Chicago"
				description="“It was easy for me to get in touch with hosts and within a few hours of reaching out to a prospective host, I had finalized my move in.”"
				avatarUrl="/img/landing/host/sonny.jpeg"
				universityUrl="/img/landing/universities/university-of-chicago.png"
			/>
			<StudentStory
				name="Jasper"
				subtitle="MBA Exchange Student at Kellogg"
				description="“Thanks to the hostU platform, I managed to secure my accommodation swiftly. This made me feel at ease with my move.”"
				avatarUrl="/img/landing/host/jasper.jpeg"
				universityUrl="/img/landing/universities/northwestern.png"
			/>
			<StudentStory
				name="Dori"
				subtitle="Junior at Northwestern University"
				description="“I cross referenced some of the listings in hostU with other real estate websites and the prices were unmatched.”"
				avatarUrl="/img/landing/host/dori.jpeg"
				universityUrl="/img/landing/universities/northwestern.png"
			/>
			<StudentStory
				name="Karla"
				subtitle="MBA Student at Monterrey"
				description="“Subletting from another student made me feel really safe and I saved hours getting custom matches.”"
				avatarUrl="/img/landing/host/karla.jpeg"
				universityUrl="/img/landing/universities/monterrey.png"
				universityClassName="rounded-full"
			/>
			<StudentStory
				name="Analisa"
				subtitle="Junior at Northwestern"
				description="“hostU connected me with subletters who were perfect matches. I was able to access other students, with matching needs, for a super quick process.”"
				avatarUrl="/img/landing/host/analisa.jpeg"
				universityUrl="/img/landing/universities/northwestern.png"
			/>
			<StudentStory
				name="Max C."
				subtitle="Sophomore at Fordham University"
				description="“I listed my place and another student reached out to me about subletting it only two days later! Since, I have recommended hostU to everyone subletting.”"
				avatarUrl="/img/landing/host/max.jpeg"
				universityUrl="/img/landing/universities/fordham-rams.png"
			/>
		</>
	);
}

type StudentStoryProps = {
	avatarUrl: string;
	name: string;
	subtitle: string;
	description: string;
	universityUrl: string;
	className?: string;
	universityClassName?: string;
};
export function StudentStory(props: StudentStoryProps) {
	const {
		avatarUrl,
		name,
		subtitle,
		description,
		universityUrl,
		universityClassName,
		className,
	} = props;

	return (
		<div
			className={cn(
				'flex max-w-[460px] gap-4 rounded-lg border border-gray-100 bg-white px-4 py-4 shadow-lg transition-colors hover:bg-gray-100/50',
				className,
			)}
		>
			<div className="relative h-fit w-fit min-w-fit">
				<img
					src={avatarUrl}
					alt="student"
					className="h-28 w-28 rounded-full object-cover"
				/>
				<img
					src={universityUrl}
					alt="student university"
					className={cn(
						'absolute -bottom-1 -right-2 h-12 w-12 object-contain',
						universityClassName,
					)}
				/>
			</div>
			<div>
				<p className="text-lg font-semibold">
					{name} <Icon name="blue-checkmark" className="h-6 w-6 shadow-none" />
				</p>
				<p className="text-sm">{subtitle}</p>
				<p className="max-w-xs text-sm text-gray-500">{description}</p>
			</div>
		</div>
	);
}
