import { Link } from '@remix-run/react';
import { Button } from '../ui/button.tsx';
import { Icon } from '../ui/icon.tsx';
import { useAppMode } from '../dashboard/app-mode-provider.tsx';

export function WhatAreYouWaitingForSection() {
	const mode = useAppMode();
	return (
		<div className="flex w-full flex-col items-center justify-center gap-6 bg-primary px-4 py-20 text-white md:px-8">
			<section className="flex w-full max-w-[1440px] flex-col items-center justify-between gap-8 md:flex-row">
				<div className="flex flex-col gap-2">
					<h3 className="text-xl font-semibold xs:text-4xl sm:text-5xl md:text-3xl">
						What are you waiting for?
					</h3>
					<p className="text-center text-lg text-gray-100 md:text-start md:text-2xl">
						{mode === 'host'
							? 'Start getting paid today'
							: 'Your personalized sublet is waiting for you'}
					</p>
				</div>
				<div>
					<Button
						variant="black"
						className="min-w-[270px] rounded-full bg-black/50 px-5 py-6 text-lg md:px-7 md:py-7 md:text-xl lg:text-2xl"
						asChild
					>
						<Link to="/app/host">
							See my dashboard{' '}
							<Icon
								name="arrow-right-wide"
								className="ml-2 hidden h-2 lg:inline"
							/>
						</Link>
					</Button>
				</div>
			</section>
		</div>
	);
}
